import { globalConfig } from "../GlobalConfig";
import moment from 'moment';

export const setPrice = (price) => {
  if (price === undefined || price === "-") {
    return globalConfig.defaultCurrency + "0";
  }
  return globalConfig.defaultCurrency + setNumberPrecision(price || 0);
};

export const setNumberPrecision = (val) => {
  return val && val.toFixed(2);
};

export const setCartTotal = (cartObj) => {
  let totalOrderAmount = 0;
  Object.keys(cartObj).map((key) => (totalOrderAmount += cartObj[key].price));
  return totalOrderAmount;
};

export const modifyDateTime = (orderDate) => {
  const year = new Date(orderDate).getFullYear();
  return (year !== 1) ? moment(orderDate).format('DD-MM-YYYY HH:mm') : '-';
}

export const modifyDate = (orderDate) => {
  const year = new Date(orderDate).getFullYear();
  return (year !== 1) ? moment(orderDate).format('MM-DD-YYYY') : '';
}

export const removeWhiteSpaces = (e) => {
  if (e.key === " ") {
    e.currentTarget.value = e.currentTarget.value.replace(/\s+/g, "").trim();
  }
};

export const blockInvalidChar = (e) => {
  ['-'].includes(e.key) && e.preventDefault();
}

export const handlePhoneChange = (event, formik) => {
  // Remove all non-numeric and non-plus characters from the input string
  let cleaned = event.target.value
    .replace(/[^0-9+]/g, "")
    .substring(0, globalConfig.allowPhoneNumberLength);

  // Check if the cleaned string starts with a plus sign
  if (cleaned.startsWith("+")) {
    // If there is another plus sign after the first character, remove it
    const secondPlusIndex = cleaned.indexOf("+", 1);
    if (secondPlusIndex !== -1) {
      cleaned =
        cleaned.slice(0, secondPlusIndex) + cleaned.slice(secondPlusIndex + 1);
    }
  } else {
    // If the cleaned string doesn't start with a plus sign, remove all plus signs
    cleaned = cleaned.replace(/\+/g, "");
  }

  // Update the value of the 'phone' field in the form with the cleaned phone number
  formik.setFieldValue("phone", cleaned);
};

export const handleNameChange = (event, formik, input) => {
  let cleaned = event.target.value
    .substring(0, globalConfig.allowNameLength)
    .trim();
  formik.setFieldValue(input, cleaned);
};

export const removeWhiteSpacesInPassword = (e, formik, input) => {
  let value = e.currentTarget.value;
  if (value.includes(" ")) {
    value = value.replace(/\s/g, "");
  }
  return formik.setFieldValue(input, value);
}